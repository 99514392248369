import React, { useEffect, useRef, useState } from 'react'
import Matter from 'matter-js'
import Star from '../assets/star.svg'
import Arch from '../assets/arch.svg'
import Cube from '../assets/cube.svg'
import Steps from '../assets/steps.svg'
import House from '../assets/house.svg'
import Box from '../assets/box.svg'
import Disc from '../assets/disc.svg'
import $ from "jquery";
import 'pathseg'

const STATIC_DENSITY = 5

const scale = (num, in_min, in_max, out_min, out_max) => {
  return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

const ShapePlayground = (props) => {
  const boxRef = useRef(null)
  const canvasRef = useRef(null)

  const [constraints, setContraints] = useState()
  const [scene, setScene] = useState()
  const [oldWidth, setOldWidth] = useState()
  const [oldHeight, setOldHeight] = useState()
  const [direction, setDirection] = useState()

  const handleResize = () => {
    setContraints(boxRef.current.getBoundingClientRect())
  }

  useEffect(() => {
    let Engine = Matter.Engine
    let Render = Matter.Render
    let World = Matter.World
    let Bodies = Matter.Bodies
    let MouseConstraint = Matter.MouseConstraint
    let Mouse = Matter.Mouse
    let Common = Matter.Common
    let Composite = Matter.Composite
    let Vertices = Matter.Vertices
    let Svg = Matter.Svg

    Common.setDecomp(require('poly-decomp'));

    let engine = Engine.create({})
    engine.world.gravity.y = 0;

    let render = Render.create({
      element: boxRef.current,
      engine: engine,
      canvas: canvasRef.current,
      options: {
        background: 'transparent',
        wireframes: false,
      },
    });


    // create bounds
    const floor = Bodies.rectangle(0, 0, 0, STATIC_DENSITY, {
        isStatic: true,
        render: {
          fillStyle: 'yellow',
        },
        label: 'floor'
    })

    const roof = Bodies.rectangle(0, 0, 0, STATIC_DENSITY, {
        isStatic: true,
        render: {
          fillStyle: 'blue',
        },
        label: 'roof'
    })

    const wallRight = Bodies.rectangle(0, 0, STATIC_DENSITY, 0, {
        isStatic: true,
        render: {
          fillStyle: 'green',
        },
        label: 'wallRight'
    })

    const wallLeft = Bodies.rectangle(0, 0, STATIC_DENSITY, 0, {
        isStatic: true,
        render: {
          fillStyle: 'red',
        },
        label: 'wallLeft'
    })

    let vertexSets = []
    let colors = ["#2B5EA1", "#469BD4", "#669F71", "#D94F46", "#EAC057", "#2B5EA1", "#469BD4", "#669F71", "#D94F46", "#EAC057"];
    let scaleFactor;
    const width = boxRef.current.getBoundingClientRect().width;
    const height = boxRef.current.getBoundingClientRect().height;

    if(width > height) {
      scaleFactor = scale(width, 0, 2600, 0, 1.8)
    } else {
      scaleFactor = scale(height, 600, 1080, 0, 1.2)
    }

    $('.shape').find('path').each(function(i, path){
        // vertexSets.push(Svg.pathToVertices(path, 100));
        var index = Math.floor( Math.random() * colors.length );
        var v = Bodies.fromVertices( Math.random() * width, Math.random() * height, Svg.pathToVertices(path), {
          render: {
            fillStyle: colors[index],
            lineWidth: 1,
            strokeStyle: colors[index]
          }
        }, true);
      Matter.Body.scale(v, scaleFactor, scaleFactor)
      colors.splice(index, 1)
      // console.log(colors)
      vertexSets.push(v);
      // World.add(engine.world, v);
    });

    World.add(engine.world, [floor, roof, wallRight, wallLeft, ...vertexSets])

    // Render.setPixelRatio(render, "auto");

    Engine.run(engine)
    Render.run(render)

    let mouse = Mouse.create(render.canvas)
    let mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
            stiffness: 0.2,
            render: {
                visible: false
            }
        }
    });

    World.add(engine.world, mouseConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;

    // Detect clicks vs. drags
    let click = false;

    document.addEventListener('mousedown', () => click = true);
    document.addEventListener('mousemove', () => click = false);
    document.addEventListener('mouseup', () => console.log(click ? 'click' : 'drag'));

    setContraints(boxRef.current.getBoundingClientRect())
    setScene(render)

    window.addEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {

  }, [scene])

  useEffect(() => {
    if (constraints) {
      let { width, height } = constraints

      // Dynamically update canvas and bounds
      scene.bounds.max.x = width
      scene.bounds.max.y = height
      scene.options.width = width
      scene.options.height = height
      scene.canvas.width = width
      scene.canvas.height = height
      scene.options.pixelRatio = 'auto';

      // Dynamically update floor
      const floor = scene.engine.world.bodies[0]
      const roof = scene.engine.world.bodies[1]
      const wallRight = scene.engine.world.bodies[2]
      const wallLeft = scene.engine.world.bodies[3]
      //console.log(scene.engine.world.bodies)

      //console.log(scene.engine.world.bodies.length)


      Matter.Body.setPosition(floor, {
        x: width / 2,
        y: height + STATIC_DENSITY / 2,
      })

      Matter.Body.setVertices(floor, [
        { x: 0, y: height },
        { x: width, y: height },
        { x: width, y: height - STATIC_DENSITY },
        { x: 0, y: height - STATIC_DENSITY },
      ])

      Matter.Body.setPosition(roof, {
        x: width / 2,
        y: - STATIC_DENSITY / 2,
      })

      Matter.Body.setVertices(roof, [
        { x: 0, y: 0 },
        { x: width, y: 0 },
        { x: width, y: 0 - STATIC_DENSITY },
        { x: 0, y: 0 - STATIC_DENSITY },
      ])

      Matter.Body.setPosition(wallRight, {
        x: width + (STATIC_DENSITY + 100) / 2,
        y: height/2,
      })

      Matter.Body.setVertices(wallRight, [
        { x: width, y: 0 },
        { x: width + STATIC_DENSITY, y: 0 },
        { x: width + STATIC_DENSITY, y: height },
        { x: width, y: height},
      ])

      Matter.Body.setPosition(wallLeft, {
        x: 0 - (STATIC_DENSITY + 100) / 2,
        y: height/2,
      })
      
      Matter.Body.setVertices(wallLeft, [
        { x: 0, y: 0 },
        { x: 0 + STATIC_DENSITY, y: 0 },
        { x: 0 + STATIC_DENSITY, y: height },
        { x: 0, y: height},
      ])

      // Matter.Body.scale(scene.engine.world.bodies[4], 0.99, 0.99)
      // Matter.Body.scale(scene.engine.world.bodies[5], 0.99, 0.99)
      // Matter.Body.scale(scene.engine.world.bodies[6], 0.99, 0.99)
      // Matter.Body.scale(scene.engine.world.bodies[7], 0.99, 0.99)
      // Matter.Body.scale(scene.engine.world.bodies[8], 0.99, 0.99)
      // Matter.Body.scale(scene.engine.world.bodies[9], 0.99, 0.99)
      // Matter.Body.scale(scene.engine.world.bodies[10], 0.99, 0.99)
    }
  }, [scene, constraints])
  
    return (
      <>
        <div
          ref={boxRef}
          className="shapePlayground"
        >
          <canvas ref={canvasRef} />
        </div>
        <Star/>
        <House/>
        <Steps/>
        <Arch/>
        <Cube/>
        <Box/>
        <Disc/>
      </>
    )
}

export default ShapePlayground