import React, {  useEffect } from 'react'


const PageWidthReceiver = (props) => {
    useEffect(() => {
        props.setPageWidth(window.innerWidth);

        function handleResize() {
          props.setPageWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return(
        <div id="pageWidthReceiver"></div>
    )
}

export default PageWidthReceiver