import React from 'react'
import { 
    TransitionGroup, 
    Transition as ReactTransition 
} from 'react-transition-group'

import "../styles/layout.scss"

const width = `calc(100vw - 32px - 32px - 240px - 240px - 10vw)`;


const timeout = 300;
const getTransitionStylesHome = {
    entering: {
        // maxWidth: `calc(1800px - 32px - 32px - 240px - 240px);`,
        position: `absolute`,
        transform: `translateY(30px)`,
        // width: width,
        zIndex: 0,
        opacity: 0,
    },
    entered: {
        // maxWidth: `calc(1800px - 32px - 32px - 240px - 240px);`,
        position: `absolute`,
        // width: width,
        transition: `opacity ${timeout}ms ease-in-out, transform ${timeout}ms ease-in-out`,
        zIndex: 0,
        opacity: 1,
    },
    exiting: {
        // maxWidth: `calc(1800px - 32px - 32px - 240px - 240px);`,
        position: `absolute`,
        // width: width,
        transition: `opacity ${timeout}ms ease-in-out, transform ${timeout}ms ease-in-out`,
        transform: `scale(0.9)`,
        zIndex: 0,
        opacity: 0,
    },
}

const getTransitionStylesOther = {
    entering: {
        // maxWidth: `calc(1800px - 32px - 32px - 240px - 240px);`,
        position: `absolute`,
        transform: `translateY(30px)`,
        // width: width,
        zIndex: 300,
        opacity: 0,
    },
    entered: {
        // maxWidth: `calc(1800px - 32px - 32px - 240px - 240px);`,
        position: `absolute`,
        // width: width,
        transition: `opacity ${timeout}ms ease-in-out, transform ${timeout}ms ease-in-out`,
        zIndex: 300,
        opacity: 1,
    },
    exiting: {
        // maxWidth: `calc(1800px - 32px - 32px - 240px - 240px);`,
        position: `absolute`,
        // width: width,
        transition: `opacity ${timeout}ms ease-in-out, transform ${timeout}ms ease-in-out`,
        transform: `scale(0.9)`,
        zIndex: 300,
        opacity: 0,
    },
}

const Transition = ({children, location, pageWidth, setTopLevelClass}) => {
    console.log(location.pathname);

    return(
        <TransitionGroup>
            <ReactTransition
                key={location.pathname}
                timeout={{
                    enter: timeout,
                    exit: timeout,
                }}>
                {status => (
                    <div className="transition" style={location.pathname == "/" && pageWidth > 700 ? {...getTransitionStylesHome[status]} : {...getTransitionStylesOther[status]} }>
                        {children}
                    </div>
                )}
            </ReactTransition>
        </TransitionGroup>
    )
}

export default Transition