import React,  { useState, useEffect }  from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ShapePlayground from "../components/shapePlayground"

import Nav from "../components/nav"
import MobileNav from "../components/mobileNav"
import Sidebar from "../components/sidebar"
import Transition from "../components/transition"
import PageWidthReceiver from "../components/pageWidthReceiver"
import CommunityFilters from "../components/communityFilters"

import "../styles/layout.scss"
import '../styles/styles.scss'

const TabletNav = (props) => {
  return(
    <div>
      <Nav location={props.location}/>
      <Sidebar setShapeActive={props.setShapeActive} shapeActive={props.shapeActive}/>
    </div>
  )
}

const CommunityNav = ({activeFilters, setActiveFilters, location}) => {
  return(
    <div className="communityBar">
      <Nav location={location}/>
      <CommunityFilters activeFilters={activeFilters} setActiveFilters={setActiveFilters}/>
      <a href="https://airtable.com/shrgJwIo4IvvEdvRA" className="submitPortfolio" target="_blank">Submit Your Portfolio</a>
    </div>
  )
}

const MobileNavSideBar = (props) => {
  return(
    <div className="mobileContainer">
      <MobileNav location={props.location}/> 
    </div>
  )
}

const Layout = ({children, location}) => {

  // const { location } = props

  const [shapeActive, setShapeActive] = useState(true);
  const [pageWidth, setPageWidth] = useState();
  const [topLevelClass, setTopLevelClass] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery2 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let navigationOption;

  useEffect(() => {    
    if(location.pathname == "/community" || location.pathname == "/community/") {
      console.log("successly passed state")
      setTopLevelClass(true)
    } else {
      console.log("fail");
      console.log(location.pathname);
      setTopLevelClass(false);
    }
  });

  

  // let topLevelClass = location.pathname == "/community" ? "toplevel darkmode": "toplevel";
 
  if(pageWidth <= 700 && location.pathname !== "/community"){
    navigationOption = <MobileNavSideBar setShapeActive={setShapeActive} shapeActive={shapeActive} location={location.pathname}/>
  } else if(pageWidth <= 700){
    navigationOption = <MobileNav location={location.pathname}/>
  } else if(pageWidth <= 1300 && !topLevelClass) {
    navigationOption = <TabletNav setShapeActive={setShapeActive} shapeActive={shapeActive} location={location.pathname}/>
  } else if (topLevelClass) {
    navigationOption = <CommunityNav location={location.pathname} activeFilters={activeFilters} setActiveFilters={setActiveFilters}/>
  } else {
    navigationOption = <Nav location={location.pathname}/>
  }

  // // useEffect(() => {    
  //   if(location != "/community") {
  //     document.querySelector("body").style.background = "black";
  //   }
  // // });

  const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
          activeFilters
      }),
  );

  return (
    <div className={topLevelClass ? "toplevel darkmode" : "toplevel"}>
      {pageWidth <= 700 && pageWidth != null ? navigationOption : null}
      <div className="layout">
        {pageWidth > 700 && pageWidth != null ? navigationOption : null}
        {pageWidth == null ? <div></div> : null}
        <main className={topLevelClass ? "toplevelmain" : null}>
            <Transition pageWidth={pageWidth} location={location} setTopLevelClass={setTopLevelClass} activeFilters={activeFilters}>{childrenWithProps}</Transition>
        </main>
        {pageWidth > 1300 && pageWidth != null && (location.pathname != "/community" && location.pathname != "/community/") ? <Sidebar setShapeActive={setShapeActive} shapeActive={shapeActive}/> : null}
        {pageWidth == null ? <div></div> : null}
      </div>
      {shapeActive ? <ShapePlayground/> : null}
      <PageWidthReceiver pageWidth={pageWidth} setPageWidth={setPageWidth}/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
