import React, { useState, useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import "../styles/communityFilters.scss"

const CommunityFilters = ({activeFilters, setActiveFilters}) => {

    const toggleFilter = (filterToToggle) => {
        console.log("hello")
        // check if in array already     
        if(activeFilters.includes(filterToToggle)){
          // if in array, remove
          let newActiveFilters = activeFilters.filter(e => e !== filterToToggle)
          setActiveFilters(newActiveFilters)
        } else {
          // if not, add to array
          let newActiveFilters = [...activeFilters, filterToToggle];
          setActiveFilters(newActiveFilters);
        }
        // console.log(activeFilters)
    }

    return(
        <div className="communityFilters">
            <div className="filterGroup seniority">
                <h4>Year</h4>
                <ul>
                    <li className={activeFilters.includes("Freshman") ? "active": null} onClick={() => toggleFilter("Freshman")}><span>Freshman</span></li>
                    <li className={activeFilters.includes("Sophomore") ? "active": null} onClick={() => toggleFilter("Sophomore")}><span>Sophomore</span></li>
                    <li className={activeFilters.includes("Junior") ? "active": null} onClick={() => toggleFilter("Junior")}><span>Junior</span></li>
                    <li className={activeFilters.includes("Senior") ? "active": null} onClick={() => toggleFilter("Senior")}><span>Senior</span></li>
                    <li className={activeFilters.includes("Alumni") ? "active": null} onClick={() => toggleFilter("Alumni")}><span>Alumni</span></li>
                </ul>
            </div>
        </div>
    )
}

export default CommunityFilters