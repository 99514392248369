import React, { useState, useEffect } from 'react'
import CopyMailTo from "react-copy-mailto"
import Clock from 'react-digital-clock'
import { Link } from 'gatsby'
import "../styles/nav.scss"

import Mail from "../assets/mail.svg"
import Discord from "../assets/discord.svg"
import Instagram from "../assets/instagram.svg"
import Arrow from "../assets/externalArrow.svg"

const Nav = (props) => {
    const [temperature, setTemperature] = useState();
  
    useEffect(() => {    
        fetch(`https://api.openweathermap.org/data/2.5/weather/?q=Savannah&units=imperial&APPID=b80aeb1f12d5d2075b17aa14014ed4e8`)
            .then(res => res.json())
            .then(result => {
            setTemperature(result.main.temp);
            // console.log(result.main.temp);
        });
    }, [])

    return(
        <div>
            <nav className={(props.location == "/community" || props.location == "/community/") ? "communityNav mainNav" : "mainNav"}>
                <ul>
                    <Link activeClassName="activeNav" className="navItem" to="/"><li>Home</li></Link>
                    {/* <Link activeClassName="activeNav" className="navItem" to="/events"><li>Events</li></Link>  */}
                    <Link activeClassName="activeNav" className="navItem" to="/events"><li>Events </li></Link>
                    <Link activeClassName="activeNav" className="navItem" to="/community"><li>Community</li></Link>
                    <a href="https://open.spotify.com/show/1kNJN4x6zYAfdEXS5646YW" target="_blank" className="navItem"><li>Podcast</li> <Arrow/></a>
                    <a href="https://scadstartup.com/" target="_blank" className="navItem"><li>Startup</li> <Arrow/></a>
                </ul>
                <div className="socialLinks navItem">
                    <a href="https://www.instagram.com/scadflux/?hl=en"><Instagram/></a>
                    <a href="https://discord.gg/m2jE9Ng58y"><Discord/></a>
                    <CopyMailTo email="scadflux@gmail.com" defaultTooltip="Copy" copiedTooltip="Copied" tooltipStyles={{background: "rgba(247, 248, 250, 0.9)", backdropFilter: "blur(15px)", border: "1px solid #B6B6C7", color: "black", textAlign: "center", fontFamily: "Space Grotesk", fontWeight: "normal", filter: "none", boxShadow: "none"}} children={<Mail/>}/>
                </div>
                <div className="weatherTime navItem lastItem">
                    <div className="left">
                        <Clock format= {'hh-mm'}/>
                        <p className="label">{new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]}</p>
                    </div>
                    <div className="right">
                        <p>{Math.round(temperature)}&deg;F</p>
                        <p className="label">Savannah, GA</p>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Nav