import React, { useState, useEffect } from 'react'
import CopyMailTo from "react-copy-mailto";
import Clock from 'react-digital-clock';
import { Link } from 'gatsby'
import { Spiral as Hamburger } from 'hamburger-react'
import { Transition } from 'react-transition-group';

import Mail from "../assets/mail.svg"
import Discord from "../assets/discord.svg"
import Instagram from "../assets/instagram.svg"
import Logo from '../assets/logo.svg'
import Arrow from "../assets/externalArrow.svg"

import "../styles/mobileNav.scss"

const duration = 300;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
  transform: 'translateY(50px)'
}

const transitionStyles = {
  entering: { 
      opacity: 0,
    //   display: 'flex',
      transform: 'translateY(50px)'
    },
  entered: { 
      opacity: 1,
      transform: 'translateY(0px)'
    },
  exiting: { 
      opacity: 0,
    //   display: 'none',
      transform: 'translateY(50px)'
    },
  exited: { 
      opacity: 0,
    //   display: 'none',
      height: "0px",
      transform: 'translateY(2050px)' 
    },
};

const MobileNav = (props) => {
    const [isOpen, setOpen] = useState(false)

    return(
        // className={props.location == "/community" ? "communityNav mobileNav" : "mobileNav"}
        <nav className={(props.location == "/community" || props.location == "/community/") ? "communityNav mobileNav" : isOpen ? "mobileNav open" : "mobileNav"}>
            <div className='navHeader'>
                <div className='left'>
                    <Logo/>
                    <p>{props.location.replace(/^\/|\/$/g, '')}</p>
                </div>
                
                
                <Hamburger size={24} toggled={isOpen} toggle={setOpen}/>
            </div>

            <Transition in={isOpen} timeout={duration}>
                {state => (
                    <div
                        className='navItems' 
                        style={{
                        ...defaultStyle,
                        ...transitionStyles[state]}}>
                        <ul>
                            <Link onClick={() => setOpen(false)} activeClassName="activeNav" className="navItem" to="/"><li>Home</li></Link>
                            {/* <Link onClick={() => setOpen(false)} activeClassName="activeNav" className="navItem" to="/events"><li>Events</li></Link> */}
                            <Link onClick={() => setOpen(false)} activeClassName="activeNav" className="navItem" to="/events"><li>Events</li></Link>
                            <Link onClick={() => setOpen(false)} activeClassName="activeNav" className="navItem" to="/community"><li>Community</li></Link>
                            <a href="https://open.spotify.com/show/1kNJN4x6zYAfdEXS5646YW" target="_blank" className="navItem"><li>Podcast</li> <Arrow/></a>
                            <a href="https://scadstartup.com/" target="_blank" className="navItem"><li>Startup</li> <Arrow/></a>

                        </ul>
                        <div className="socialLinks navItem lastItem">
                            <a href="https://www.instagram.com/scadflux/?hl=en"><Instagram/></a>
                            <a href="https://discord.gg/RMsJ6PGv"><Discord/></a>
                            <CopyMailTo email="scadflux@gmail.com" defaultTooltip="Copy" copiedTooltip="Copied" tooltipStyles={{background: "rgba(247, 248, 250, 0.9)", backdropFilter: "blur(15px)", border: "1px solid #B6B6C7", color: "black", textAlign: "center", fontFamily: "Space Grotesk", fontWeight: "normal", filter: "none", boxShadow: "none"}} children={<Mail/>}/>
                        </div>
                    </div>
                )}
            </Transition>
        </nav>
    )
}

export default MobileNav